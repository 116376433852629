.ActionImage {
    position: absolute;
    cursor: initial !important;
}

.ActionImage .image {
    position: absolute;
    width: 100%;
}

.ActionImage.clickable {
    cursor: pointer !important;
}

.ActionImage .a {
    opacity: 1;
}

.ActionImage .b {
    opacity: 0;
}

.ActionImage.active .a {
    opacity: 0;
}

.ActionImage.active .b {
    opacity: 1;
}
