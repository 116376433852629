.Arena #jalien {
    position: absolute;
    left: 31px;
    top: 29px;
}

.Arena #koe {
    position: absolute;
    left: 139px;
    top: 28px;
}

.Arena #knoppen {
    position: absolute;
    left: 81px;
    top: 101px;

    width: 54px;
    height: 20px;

    /* background-image: url('../assets/10/achtergrond\ knoppen.png'); */
}

#vuist-knop {
    position: relative;
    left: 3px;
    top: 1px;

    width: 14px;  
}

#bom-knop {
    position: relative;
    left: 20px;
    top: 1px;

    width: 14px;
}

#tranen-knop {
    position: relative;
    left: 37px;
    top: 1px;

    width: 14px;
}

#vuist {
    position: absolute;
    left: 31px;
    top: 24px;
}

#bom {
    position: absolute;
}

#tranen {
    position: absolute;
    left: 54px;
    top: 30px;
}

#vuur {
    position: absolute;
    left: 51px;
    top: 20px;
}


.healthBar {
    position: absolute;
    width: 36px;
    height: 7px;
}



#healthBar-jalien {
    left: 29px;
    top: 112px;
}

#healthBar-koe {
    left: 154px;
    top: 112px;
}

.healthBarVoor {
    transition-duration: 1s;
    width: 34px;
    /* transition-timing-function: steps(4, end); */
}


.attack {
    z-index: 1;
}

.Arena #koe-intro {
    pointer-events: none;
}


.Arena .hart {
    position: absolute;
    top: 4px;
    width: 11px;
}

.Arena .hart#one {
    left: 4px;
}

.Arena .hart#two {
    left: 19px;
}

.Arena .hart#three {
    left: 34px;
}