#Page4 #paard {
    position: absolute;

    width: 12%;
    top: 60%;
    left: 63%;
}

#Page4 #auto {
    position: absolute;

    width: 22%;
    top: 42%;
    left: 22%;
}

#Page4 #vliegtuig {
    position: absolute;
    width: 43%;
    top: 53%;
    left: 25%;
}

#Page4 #schaduw {
    display: inline-block;

    position: absolute;
    width: 43%;
    top: 85%;
    left: 28%;
    
    -webkit-transform: scaleY(-1.2) skewX(-20deg);
    -moz-transform: scaleY(-1.2) skewX(-20deg);
    -o-transform: scaleY(-1.2) skewX(-20deg);
    transform: scaleY(-1.2) skewX(-20deg);
    filter: opacity(0.35) grayscale(0.5) brightness(0.2);
}