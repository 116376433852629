.HoverImage {
    position: absolute;

    cursor: pointer;
}

.HoverImage .image {
    position: absolute;
    width: 100%;
}

.HoverImage .a {
    opacity: 1;
}

.HoverImage .b {
    opacity: 0;
}

.HoverImage:hover .a {
    opacity: 0;
}

.HoverImage:hover .b {
    opacity: 1;
}
