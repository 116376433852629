#Page2 #boom {
    position: absolute;

    width: 26%;
    left: 57%;
    top: 30%;
}

#Page2 #boom.pointer {
    cursor: pointer;
}

#Page2 #vogel {
    pointer-events: none;
}

#Page2 #koe {
    position: absolute;

    width: 12%;
    left: 15%;
    top: 70%;
}

#Page2 #wolk {
    position: absolute;

    width: 25%;
    left: 72%;
    top: 3%;
}

#Page2 #video {
    pointer-events: none;
}