#Page8 #vis {
    position: absolute;

    width: 14%;
    left: 13.5%;
    top: 40%; 
}

#Page8 #klok {
    position: absolute;

    width: 12%;
    left: 74%;
    top: 4%; 
}

#Page8 #brood {
    position: absolute;

    width: 8%;
    left: 40.5%;
    top: 45.5%; 
}

#Page8 #telefoon {
    position: absolute;

    width: 7%;
    left: 5%;
    top: 20%; 
}

#Page8 #vaat {
    position: absolute;

    width: 9%;
    right: 0;
    top: 40%;

    pointer-events: none;
}

#Page8 #lamp {
    position: absolute;

    width: 20%;
    left: 34%;
    top: 0;
}

#Page8 #kraan {
    position: absolute;

    width: 16%;
    left: 82%;
    top: 26%;
}