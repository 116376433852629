#Page6 #rook {
    position: absolute;
    width: 16%;
    left: 75.5%;
    top: 0;
    opacity: 0.85;
}

#Page6 #deur {
    position: absolute;
    width: 15%;
    left: 58.5%;
    top: 37%;
}

#Page6 #paard {
    position: absolute;
    width: 60%;
    left: -9%;
    top: 30%;
}

#Page6 #auto {
    position: absolute;
    width: 80%;
    left: -25%;
    top: 10%;
}