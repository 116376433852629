.Window {
    position: absolute;

    height: 90vh;
    width: calc(90vh * 16 / 9);

    margin-top: 5vh;
    margin-left: 50%;

    transform: translateX(-50%);

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    overflow: hidden;

    background-color: white;
}