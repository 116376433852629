@keyframes wolkAnimation {
    0% { background-position-x: 0%; }
    100% { background-position-x: 100%; }
}

#Page1 {
    background-size: cover;
    background-repeat: repeat-x;
    animation-name: wolkAnimation;
    animation-duration: 180s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.golf {
    position: absolute;
    width: 110%;

    animation-name: animationGolf;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

    pointer-events: none;
}

@keyframes animationGolf {
    0% { left: -10%; }
    50% { left: 0; }
    100% { left: -10%; }
}

#golf1 {
    bottom: 0;
    animation-duration: 8s;
}

#golf2 {
    bottom: 5%;
    animation-duration: 8s;
    animation-delay: -4s;
}

#golf3 {
    bottom: 15%;
    animation-duration: 8s;
}

#golf4 {
    bottom: 25%;

    animation-duration: 8s;
    animation-delay: -4s;
}

#golf5 {
    bottom: 30%;

    animation-duration: 8s;
}

#meeuw {
    position: absolute;
    width: 60%;
}

#vissen {
    position: absolute;
    top: 57%;
    left: 10%;
    width: 20%;

    cursor: pointer;
}

#boot {
    position: absolute;
    /* top: 16%; */
    /* left: 10%; */
    width: 100%;

    pointer-events: none;
}

@keyframes flesAnimation {
    0% { transform: translateY(0) rotate(0deg); }
    50% { transform: translateY(20%) rotate(15deg); }
    100% { transform: translateY(0) rotate(0deg); }
}

#fles {
    position: absolute;
    left: 63%;
    top: 72%;
    width: 10%;

    animation-name: flesAnimation;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

    cursor: pointer;
}

@keyframes boeiAnimation {
    0% { transform: translateY(0) rotate(-5deg); }
    50% { transform: translateY(5%) rotate(5deg); }
    100% { transform: translateY(0) rotate(-5deg); }
}

#boei {
    position: absolute;
    left: 77%;
    top: 32%;
    width: 15%;
    
    animation-name: boeiAnimation;
    animation-duration: 8s;
    animation-delay: -2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

    transition: top;
    transition-duration: 1.9s;
    transition-delay: 2.3s;
    /* transition-timing-function: linear; */
}

#boei.clickAble {
    cursor: pointer;
}


#tentakel-voor {
    position: absolute;
    left: 75%;
    top: 44%;
    width: 20%;

    pointer-events: none;
}


#tentakel-achter {
    position: absolute;
    left: 75%;
    top: 65%;
    width: 20%;

    pointer-events: none;
    
    transition: top;
    transition-duration: 3s;
}


#Page1 #huis {
    position: absolute;
    left: 46%;
    top: 34.25%;
    width: 3.2%;

    opacity: 0;

    cursor: pointer;
}

#Page1 #huis:hover {
    opacity: 1;
}

#Page1 #circus {
    position: absolute;
    left: 69.5%;
    top: 56.25%;
    width: 5%;

    opacity: 0;

    cursor: pointer;
}

#Page1 #circus:hover {
    opacity: 1;
}