#pijltjes {
    position: absolute;

    left: 162px;
    top: 5px;
}

.Overworld .hart {
    position: absolute;
    top: 4px;
    width: 11px;
}

.Overworld .hart#one {
    left: 4px;
}

.Overworld .hart#two {
    left: 19px;
}

.Overworld .hart#three {
    left: 34px;
}


.Overworld #jalien {
    position: absolute;

    top: 34px;
    /* left: 16px; */

    width: 29px;
    height: 66px;
}


.Overworld #koe {
    position: absolute;

    top: 18px;
    /* left: 140px !important; */
}


.Overworld #transitie {
    position: absolute;
    height: 102px;
    left: 8px;
    bottom: 22px;
}