#bordBoven {
    position: absolute;
    left: 67%;
    top: 37%;

    width: 12%;
}

#bordOnder {
    position: absolute;
    left: 66%;
    top: 47%;

    width: 14%;
}

#lieveheersbeestje {
    position: absolute;
    right: 0;
    top: 78%;
    width: 20%;
}

#Page9 #rook {
    position: absolute;
    left: 0;
    top: 0;
    width: 26%;
}

#Page9 #paal {
    position: absolute;
    left: 61%;
    top: 34%;
    width: 20%;
}