html, body, #root, .App {
    width: 100%;
    height: 100%;
}

.fullSize {
    position: absolute;
    width: 100%;
    height: 100%;
}

img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

#laadscherm {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: white;

    transition: opacity;
    transition-duration: 1s;

    pointer-events: none;
}

#laadscherm-image {
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.Page {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;

    animation-name: fadeIn;
    animation-duration: 1s;
}



/* .fadeIn {
    animation-name: fadeIn;
    animation-duration: 1s;
} */

#returnButton {
    position: absolute;
    width: 20%;
    bottom: 17%;
    right: 5%;
}